<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the number of
        <stemble-latex :content="'$\\sigma$'" />
        - and
        <stemble-latex :content="'$\\pi$'" />
        -bonds present in the molecule shown below:
      </p>

      <img :src="imageFile" width="400" height="150" class="ml-8 mb-8" />

      <calculation-input
        v-model="inputs.input1"
        :prepend-text="'$\\text{Number:}$'"
        :append-text="'$\\sigma\\text{-bonds}$'"
        :disabled="!allowEditing"
      />
      <br />

      <calculation-input
        v-model="inputs.input2"
        :prepend-text="'$\\text{Number:}$'"
        :append-text="'$\\pi\\text{-bonds}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question46',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    imageNumber() {
      return this.taskState.getValueBySymbol('imageNumber').content.value;
    },
    imageFile() {
      if (this.imageNumber === 1) {
        return '/img/assignments/SigmaPiV1.png';
      } else if (this.imageNumber === 2) {
        return '/img/assignments/SigmaPiV2.png';
      } else if (this.imageNumber === 3) {
        return '/img/assignments/SigmaPiV3.png';
      }
    },
  },
};
</script>
